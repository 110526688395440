import { useEffect, useState, useCallback } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import axios from 'axios'

export default function UserTopItems() {
  const [topTracks, setTopTracks] = useState<{ id: string; name: string }[]>([])
  // const [topArtists, setTopArtists] = useState<{id: string, name: string}[]>([]);

  const { token } = useAuthContext()

  const fetchCurrentUserTopTracks = useCallback(async () => {
    const { data } = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    setTopTracks(data.items)
  }, [token])

  useEffect(() => {
    if (token) {
      fetchCurrentUserTopTracks().then()
    }
  }, [token, fetchCurrentUserTopTracks])

  return (
    <>
      <h1>User Top Items</h1>
      <div>
        <div>
          Tracks
          <ul>
            {topTracks.map((track: any) => (
              <li key={track.id}>
                <b>{track.name}</b>{' '}
                {track.artists.map((artist: any) => (
                  <span key={artist.id}>{artist.name}</span>
                ))}
              </li>
            ))}
          </ul>
          <pre>{/*{JSON.stringify(topTracks, null, 2)}*/}</pre>
        </div>
        <div>Artist</div>
      </div>
    </>
  )
}
