import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './contexts/AuthContext'
import { ChakraProvider } from '@chakra-ui/react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import theme from './routes/_shared/layout/theme'
import Playlists from './routes/playlists'
import ErrorPage from './routes/_shared/pages/ErrorPage'
import Top from './routes/top'
import Root from './routes/root'
import Playlist from './routes/playlists/show'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Playlists />,
      },
      {
        path: '/playlists',
        element: <Playlists />,
        children: [
          {
            path: ':id',
            element: <Playlist />,
          },
        ],
      },
      {
        path: 'top',
        element: <Top />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
