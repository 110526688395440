import Axios from 'axios'

export const spotifyApi = async () => {
  const token = window.localStorage.getItem('st')
  const axios = Axios.create({
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    baseURL: 'https://api.spotify.com',
    timeout: 1000,
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response?.status === 401) {
        console.log('401! ', error.response)
        window.localStorage.removeItem('st')
        window.location.href = '/'
      } else if (error.response?.status === 403) {
        console.log('403! ', error.response)
      } else if (error.response?.status === 404) {
        console.log('404! ', error.response)
      }
      return Promise.reject(error.response)
      //TODO: handle other response errors
    },
  )
  return axios
}

export const getUserPlaylists = async () => {
  const spotify = await spotifyApi()
  return spotify
    .get('/v1/me/playlists')
    .then((response) => {
      if (response.status === 200) {
        return response.data
      } else {
        return Promise.reject(response)
      }
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const getPlaylistTracks = async (playlistId: string) => {
  const spotify = await spotifyApi()
  return spotify.get(`/v1/playlists/${playlistId}/tracks`)
}
