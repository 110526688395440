import { Link, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import {
  Heading,
  Spacer,
  Flex,
  Box,
  Divider,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
} from '@chakra-ui/react'
import { useAuthContext } from '../../contexts/AuthContext'
export default function Playlist() {
  const { id } = useParams()
  const { token } = useAuthContext()
  const [playlist, setPlaylist] = useState<any | null>(null)
  // const [artists, setArtists] = useState<any[]>([])
  const fetchPlaylist = useCallback(async (id: string) => {
    // Do we already have the tracks in state?
    const { data } = await axios.get(
      `https://api.spotify.com/v1/playlists/${id}?fields=name,tracks.items(track(name,album(name),artists(id,name),popularity))`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    setPlaylist(data)
    // eslint-disable-next-line
  }, [])

  const avgPopularity = (tracks: any) => {
    const sum = tracks.reduce((acc: number, item: any) => {
      return acc + item.track.popularity
    }, 0)
    return Math.round(sum / tracks.length)
  }

  const songCountByArtist = (tracks: any) => {
    const artistMap = new Map()

    tracks.forEach((item: any) => {
      item.track.artists.forEach((artist: any) => {
        if (artistMap.has(artist.name)) {
          artistMap.set(artist.name, artistMap.get(artist.name) + 1)
        } else {
          artistMap.set(artist.name, 1)
        }
      })
    })
    return artistMap
  }

  useEffect(() => {
    if (id) {
      fetchPlaylist(id).then()
    }
  }, [id, fetchPlaylist])
  return (
    <div>
      <Container maxW="3xl" mt={5} bg={useColorModeValue('white', 'gray.900')}>
        {playlist && (
          <>
            <Flex pt={5}>
              <Heading size={'xl'}>{playlist.name}</Heading>
              <Spacer />
              <Heading size={'xl'} color={'gray.400'}>
                {avgPopularity(playlist.tracks.items)}
              </Heading>
            </Flex>
            <Divider mt={5} />
            <Tabs>
              <TabList>
                <Tab>{playlist.tracks.items.length} Songs</Tab>
                <Tab>Data</Tab>
                {/*<Tab>Three</Tab>*/}
              </TabList>

              <TabPanels>
                <TabPanel>
                  {playlist.tracks.items.map((item: any) => (
                    <Flex key={item?.artists?.first?.id}>
                      <Box w={'50%'}>{item.track.name}</Box>
                      <Box w={'50%'}>
                        {item.track.artists.map((artist: any, i: number) => (
                          // <Link to={`artists/${artist.id}`} key={artist.id}>
                          <span key={artist.id}>
                            <Link to={`#`} key={artist.id}>
                              {artist.name}
                            </Link>
                            {i % 2 === 0 && i !== item.track.artists.length - 1 ? ', ' : ''}
                          </span>
                          // </Link>
                        ))}
                      </Box>
                    </Flex>
                  ))}
                </TabPanel>
                <TabPanel>
                  <Heading size={'xl'}>Song popularity avg {avgPopularity(playlist.tracks.items)}</Heading>
                  <Box>
                    {Array.from(songCountByArtist(playlist.tracks.items)).map((item: any) => (
                      <Flex key={item[0]}>
                        <Box w={'50%'}>{item[0]}</Box>
                        <Box w={'50%'}>{item[1]}</Box>
                      </Flex>
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Container>
    </div>
  )
}
