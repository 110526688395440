import { Outlet } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import GuestHome from './_shared/pages/GuestHome'
import AppBar from './_shared/layout/AppBar'
import { PlaylistsProvider } from '../contexts/PlaylistsContext'

export default function Root() {
  const { token } = useAuthContext()

  return (
    <>
      {!token ? (
        <GuestHome />
      ) : (
        <PlaylistsProvider>
          <AppBar />
          <Outlet />
        </PlaylistsProvider>
      )}
    </>
  )
}
