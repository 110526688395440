import React, { FC } from 'react'
import { Heading, Avatar, Box, Center, useColorModeValue } from '@chakra-ui/react'
import { Playlist } from '../../../types'
import { Link } from 'react-router-dom'

interface PlaylistTileProps {
  playlist: Playlist
}

const PlaylistTile: FC<PlaylistTileProps> = ({ playlist }) => {
  const { name, id, images } = playlist

  return (
    <Center py={2} w={'100%'}>
      <Box
        maxW={'100%'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'xl'}
        rounded={'md'}
        p={2}
        textAlign={'left'}
      >
        <Link to={`/playlists/${id}`}>
          <Heading size={'md'} fontFamily={'body'}>
            {images && images.length > 0 && (
              <Avatar size={'sm'} src={images[0].url} mr={4} pos={'relative'} />
            )}
            {name}
          </Heading>
          {/*
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          {snapshot_id}
        </Text>

        <Text
          textAlign={'center'}
          color={useColorModeValue('gray.700', 'gray.400')}
          px={3}>
          Actress, musician, songwriter and artist. PM for work inquires or{' '}
          <Link href={'#'} color={'blue.400'}>
            #tag
          </Link>{' '}
          me in your posts
        </Text>

        <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue('gray.50', 'gray.800')}
            fontWeight={'400'}>
            #art
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue('gray.50', 'gray.800')}
            fontWeight={'400'}>
            #photography
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue('gray.50', 'gray.800')}
            fontWeight={'400'}>
            #music
          </Badge>
        </Stack>
*/}

          {/*<Stack mt={8} direction={'row'} spacing={4}>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            _focus={{
              bg: 'gray.200',
            }}>
            Message
          </Button>
          <Button
            colorScheme={'green'}
            bg={'green.400'}
            rounded={'full'}
            px={6}
            _hover={{
              bg: 'green.500',
            }}>
            <Link href={signInUrl}>
              Login to Spotify and Get Started
            </Link>
          </Button>
          <Link to={`/playlists/${id}`}>
            <Button
              width={'100%'}
              fontSize={'sm'}
              rounded={'full'}
              colorScheme={'green'}
              bg={'green.400'}
              color={'white'}
              boxShadow={'0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'}
              _hover={{
                bg: 'green.500',
              }}
              _focus={{
                bg: 'green.500',
              }}
            >
              Explore
            </Button>
          </Link>
        </Stack>*/}
        </Link>
      </Box>
    </Center>
  )
}

export default PlaylistTile
