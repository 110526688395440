import PlaylistTile from './playlists/_cmp/PlaylistTile'
import { Outlet } from 'react-router-dom'
import { Box, Flex } from '@chakra-ui/react'
import { usePlaylistsContext } from '../contexts/PlaylistsContext'
import { Playlist } from '../types'

export default function Playlists() {
  const { playlists, loading } = usePlaylistsContext()

  return !loading ? (
    <Flex alignItems={'top'} justifyContent={'space-between'}>
      <Box width={'35%'} mt={3} ml={5}>
        {playlists.map((playlist: Playlist) => (
          <PlaylistTile key={playlist.id} playlist={playlist} />
        ))}
      </Box>
      <Box width={'65%'}>
        <Outlet />
      </Box>
    </Flex>
  ) : null
}
