import { useState, createContext, useContext, ReactNode, useEffect, useRef } from 'react'
import { getUserPlaylists } from '../routes/_shared/services/spotify'

const defaultContext = {
  playlists: [],
  loading: false,
}

export const PlaylistsContext = createContext(defaultContext)

export const PlaylistsProvider = ({ children }: { children: ReactNode }) => {
  const [playlists, setPlaylists] = useState<[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const mountedRef = useRef<boolean>(true)

  // Track if component is mounted
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchPlaylists = async () => {
      setLoading(true)
      return await getUserPlaylists()
    }

    fetchPlaylists()
      .then((data) => {
        if (mountedRef.current) {
          setPlaylists(data.items)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <PlaylistsContext.Provider
      value={{
        playlists,
        loading,
      }}
    >
      {children}
    </PlaylistsContext.Provider>
  )
}

export function usePlaylistsContext() {
  return useContext(PlaylistsContext)
}
