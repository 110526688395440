import { useState, createContext, useContext, ReactNode, useEffect } from 'react'

const defaultContext = {
  token: '',
  logout: () => {},
  signInUrl: '',
}

export const AuthContext = createContext(defaultContext)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const CLIENT_ID = '174a4cf8220e4160bb26d0c84a15cc58'
  // const REDIRECT_URI = 'http://localhost:3000'
  const REDIRECT_URI = 'https://plylst.netlify.app'

  const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize'
  const RESPONSE_TYPE = 'token'
  const SCOPES = 'playlist-read-private user-top-read user-read-email user-read-private'

  const [token, setToken] = useState<string>('')
  // const [user, setUser] = useState<any>(null)

  useEffect(() => {
    const hash = window.location.hash
    let token = window.localStorage.getItem('st')

    if (!token && hash) {
      token =
        hash
          .substring(1)
          .split('&')
          .find((elem) => elem.startsWith('access_token'))
          ?.split('=')[1] || ''

      window.location.hash = ''
      // Set the spotify_token (st) in localStorage
      window.localStorage.setItem('st', token)
    }
    setToken(token || '')
  }, [token])

  const logout = () => {
    setToken('')
    window.localStorage.removeItem('st')
  }

  const signInUrl = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPES}`

  return (
    <AuthContext.Provider
      value={{
        token,
        logout,
        signInUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
